import React, { ReactElement } from "react";

import { Privacy } from "../components/Footer/Privacy";

export interface PrivacyPolicy {
  success: boolean;
  content: string;
}

const Index = (): ReactElement => {
  return <Privacy />;
};

export default Index;
